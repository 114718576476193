<template>
	<v-app-bar fixed app color="sidebarBackground">
		<v-toolbar color="sidebarBackground" flat>
			<v-btn plain x-large rounded :to="{ name: 'Landing' }" class="text-none pl-0">
				<v-avatar size="48" class="mr-4" v-if="$vuetify.breakpoint.lgAndUp">
					<v-img src="@/assets/images/icon/logo-oversos-second-2x.png" />
				</v-avatar>

				<v-toolbar-title>
					<span style="font-family: Tahoma">OverSOS</span>
				</v-toolbar-title>
			</v-btn>
		</v-toolbar>

		<v-spacer />

		<template v-if="authenticated">
			<template v-if="$vuetify.breakpoint.lgAndUp">
				<v-btn v-if="authenticated && setupInfo.joinedCompany" text rounded :to="{ name: 'Company' }">
					{{ $t('companies.company') }}
				</v-btn>
				<v-btn v-if="authenticated && setupInfo.joinedCompany" text rounded :to="{ name: 'Offers' }">
					{{ $t('offers.offers') }}
				</v-btn>
				<v-btn v-if="authenticated && setupInfo.joinedCompany" text rounded :to="{ name: 'Events' }">
					{{ $t('events.events') }}
				</v-btn>
				<v-btn v-if="authenticated && setupInfo.joinedCompany" text rounded :to="{ name: 'Messages' }">
					{{ $t('nav.messages') }}
				</v-btn>
				<v-divider vertical class="mx-2" />
			</template>
			<NotificationsWidget v-if="setupInfo.verified && setupInfo.joinedCompany" />
			<NavbarOptionsWidget />
		</template>
		<template v-else>
			<v-btn text rounded :to="{ name: 'AuthLogin', query: { redirect: $route.path } }">
				{{ $t('auth.login') }}
			</v-btn>
			<v-btn text rounded :to="{ name: 'AuthRegister' }">
				{{ $t('auth.register') }}
			</v-btn>
		</template>
	</v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Navbar',
	components: {
		NotificationsWidget: () => import('@/components/main/NotificationsWidget.vue'),
		NavbarOptionsWidget: () => import('@/components/main/NavbarOptionsWidget.vue')
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo'
		})
	}
}
</script>
